import React from "react";

import { BlogListStyled, BlogListItemStyled } from "./styles";
import BlogListItem from "./../BlogListItem";

interface IProps {
  items: [];
}

const BlogList: React.FC<IProps> = ({ items }) => {
  return (
    <BlogListStyled>
      {items.map((node) => {
        return (
          <BlogListItemStyled key={node.postId}>
            <BlogListItem data={node} />
          </BlogListItemStyled>
        );
      })}
    </BlogListStyled>
  );
};

export default BlogList;
