import React from "react";
import { format } from "date-fns";

import {
  BlogItemStyled,
  BlogItemMediaStyled,
  BlogItemContentStyled,
  BlogItemTitleStyled,
  BlogItemSubtitleStyled,
  BlogItemDescStyled,
  BlogItemActionStyled,
  BlogItemActionButtonStyled,
} from "./styles";
import Img from "./../Image";

interface IProps {
  data: [];
}

const BlogItem: React.FC<IProps> = ({ data }) => {
  return (
    <BlogItemStyled to={`/blogs/${data.slug}/`}>
      <BlogItemMediaStyled>
        <Img
          fluid={["", data.featuredImage.node.imageFile]}
          alt={`${data.title} image`}
        />
      </BlogItemMediaStyled>
      <BlogItemContentStyled>
        <BlogItemTitleStyled>
          <div dangerouslySetInnerHTML={{ __html: data.title }}></div>
        </BlogItemTitleStyled>
        <BlogItemSubtitleStyled>
          BY <span>{data.author.node.name && data.author.node.name}</span> /{" "}
          {format(new Date(data.date), "MMMM dd, yyyy")}
        </BlogItemSubtitleStyled>
        <BlogItemDescStyled
          dangerouslySetInnerHTML={{ __html: data.excerpt }}
        />
        <BlogItemActionStyled>
          <BlogItemActionButtonStyled>Read More</BlogItemActionButtonStyled>
        </BlogItemActionStyled>
      </BlogItemContentStyled>
    </BlogItemStyled>
  );
};

export default BlogItem;
