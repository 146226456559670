import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/Seo";
import Layout from "../components/Layout";
import Container from "../components/Container";
import ContentWrapper from "../components/ContentWrapper";
import Section from "../components/Section";
import Inner from "../components/Inner";
import Banner from "../components/Banner";
import BlogList from "../components/BlogList";
import { Heading6, Typo10 } from "../components/Typography";
import { Flex, FlexItem } from "../components/Flex";
import CtaSection from "../components/CtaSection";

const BlogPage = ({ data }: { data: any }) => {
  const BLOG_ARRAY = data.wp.posts.nodes;
  const WP_SEO = data.wp.pages.edges[0].node.seo;
  return (
    <Layout 
      headerLocation="blog"
      footerMobileMargin={30}
      footerBorderOnMobile={true}
    >
      <SEO
        title={WP_SEO.title}
        description={WP_SEO.metaDesc}
        url={'/blogs/'}
        image={WP_SEO.opengraphImage.sourceUrl}
      />
      <ContentWrapper isBlue>
        <Container>
          <Inner noOffsetMobile>
            <Section>
              <Banner
                image={[data.bannerMobile, data.bannerDesktop]}
                altText="Blog banner"
                content={
                  <Flex flexDirection={["column", "", "row"]}>
                    <FlexItem
                      width={["100%", "", "60%"]}
                      pb={["10px", "", "0"]}
                      pt={["16px", "", "60px"]}
                    >
                      <Container width={"100%"} pr={["", "62px"]}>
                        <Heading6 as="h1" fontWeight="700">
                          Welcome to our blog
                        </Heading6>
                        <Typo10 fontWeight="400">
                          Your source for remote work insights & inspiration
                        </Typo10>
                      </Container>
                    </FlexItem>
                  </Flex>
                }
              />
            </Section>
          </Inner>
        </Container>

        <Container
          zIndex={["1", "1", "auto"]}
          bg={["white", "white", "transparent"]}
          pt={["25px", "35px"]}
          pb={["25px", "50px"]}
        >
          <Inner>
            <Section>
              <Inner bg="white" isRelative isSmall noOffsetMobile>
                <BlogList items={BLOG_ARRAY} />
              </Inner>
            </Section>
          </Inner>
        </Container>
        <Container pt={["220px", "180px", "0"]} pb={["0", "86px"]}>
          <Inner noOffsetMobile>
            <Section ptBody={["", "2.5rem"]}>
              <CtaSection
                isAlt
                heading="<p>We want to help you find an <mark>amazing match</mark></p>"
                subheading="Get in touch and let's get started"
                image={["", data.ctaImage]}
                leftButtonUrl={`https://${process.env.GATSBY_PUBLIC_DOMAIN}/companies`}
                leftButtonText="Hire a great candidate"
                leftButtonTarget="_blank"
                rightButtonUrl="/jobs/"
                rightButtonText="Get hired by a great company"
              />
            </Section>
          </Inner>
        </Container>
      </ContentWrapper>
    </Layout>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query {
    wp {
      pages(where: { name: "blogs" }) {
        edges {
          node {
            seo {
              title
              canonical
              focuskw
              metaDesc
              metaKeywords
              metaRobotsNofollow
              metaRobotsNoindex
              opengraphAuthor
              opengraphDescription
              opengraphModifiedTime
              opengraphPublishedTime
              twitterDescription
              twitterTitle
              opengraphPublisher
              opengraphSiteName
              opengraphUrl
              opengraphType
              opengraphTitle
              breadcrumbs {
                text
                url
              }
              opengraphImage {
                altText
                caption
                commentCount
                commentStatus
                databaseId
                date
                dateGmt
                description
                desiredSlug
                guid
                id
                isRestricted
                link
                mediaItemId
                mediaItemUrl
                mediaType
                mimeType
                modified
                modifiedGmt
                sizes
                slug
                sourceUrl
                srcSet
                status
                title
                seo {
                  breadcrumbs {
                    text
                    url
                  }
                  canonical
                  focuskw
                  metaDesc
                  metaKeywords
                  metaRobotsNofollow
                  metaRobotsNoindex
                  opengraphAuthor
                  opengraphDescription
                  opengraphModifiedTime
                  opengraphPublishedTime
                  opengraphPublisher
                  opengraphSiteName
                  opengraphTitle
                  opengraphType
                  opengraphUrl
                  title
                  twitterDescription
                  twitterTitle
                }
                enclosure
              }
              twitterImage {
                altText
                caption
                commentCount
                commentStatus
                databaseId
                date
                dateGmt
                description
                desiredSlug
                enclosure
                guid
                id
                isRestricted
                link
                mediaItemId
                mediaItemUrl
                title
                status
                srcSet
                sourceUrl
                slug
                sizes
                modifiedGmt
                modified
                mimeType
                mediaType
              }
            }
          }
        }
      }
      posts {
        nodes {
          postId: databaseId
          status
          slug
          id
          date
          dateGmt
          author {
            node {
              name
              url
            }
          }
          title
          tags {
            nodes {
              name
              id
            }
          }
          categories {
            nodes {
              id
              name
            }
          }
          content
          excerpt
          featuredImage {
            node {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: NONE
                    width: 555
                    quality: 70
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
    bannerDesktop: file(relativePath: { eq: "blogs-banner.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: NONE
          width: 1400
          quality: 80
          formats: [AUTO, WEBP]
        )
      }
    }
    bannerMobile: file(relativePath: { eq: "blogs-banner-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: NONE
          width: 768
          quality: 60
          formats: [AUTO, WEBP]
        )
      }
    }
    ctaImage: file(relativePath: { eq: "cta-person-2-img.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: NONE
          width: 320
          quality: 70
          formats: [AUTO, WEBP]
        )
      }
    }
  }
`;
